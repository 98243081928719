<template>
  <div class="home-main">
    <VueEditor style="flex: 1;" v-model="model.content"></VueEditor>
    <div class="home-footer">
      <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2024059465号</a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { VueEditor } from 'vue2-editor';
export default {
  name: 'HomeView',
  components: {
    VueEditor
  },
  data(){
    return {
      model: {
        content: ""
      }
    }
  }
}
</script>
<style>
.home-main{
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
